import { images } from 'assets'
import { useSelector } from 'react-redux'
import { Button } from 'components'

const UpholdWalletHeader = ({
  connectUpholdWallet = () => {},
  disconnectUpholdWallet = () => {}
}) => {
  const { upholdAmlStatus, hasUphold, upholdIsApproved } = useSelector((state) => state.walletSlice)
  return (
    <>
      <div>
        <div className='uphold-wallet-row uphold-wallet-row-header'>
          <div className='medium_2'>
            <img src={images['wallet-uphold']} alt='uphold' />
            Uphold
          </div>
          <div>
            {(!hasUphold || upholdAmlStatus === 'REJECTED') && (
              <Button className='link primary' onClick={connectUpholdWallet} ariaLabel='Link Uphold Account'>
                Link Account
              </Button>
            )}
            {(hasUphold && upholdAmlStatus !== 'REJECTED') && (
              <Button className={`link ${upholdIsApproved ? 'primary' : 'gray no-wrap'}`} onClick={upholdIsApproved ? disconnectUpholdWallet : null} data-testid='uphold-disconnect'>
                {upholdIsApproved ? 'Disconnect' : 'Under Review'}
              </Button>
            )}
          </div>
        </div>
      </div>
    </>
  )
}

export default UpholdWalletHeader
