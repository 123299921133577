import { getDomain } from 'utils'
const SignupTerms = ({ isFromOrigination = false }) => {
  if (isFromOrigination) {
    return (
      <div className='signup-terms font-CodecColdRegular'>
        By clicking &quot;Create Account&quot; below, I agree to the{' '}
        <a href={getDomain('/terms-of-use', true)} rel='noopener noreferrer' className='inline-text-link' target='_blank'>Linqto Terms of Use</a>
        {' '}and{' '}
        <a target='_blank' className='inline-text-link' rel='noopener noreferrer' href={getDomain('/privacy', true)}> Privacy Policy</a>.
      </div>
    )
  }
  return (
    <div className='mb-4 signup-terms'>
      By clicking &quot;Start Investing&quot; below, I agree to the{' '}
      <a href={getDomain('/terms-of-use', true)} rel='noopener noreferrer' className='inline-text-link' target='_blank'>Linqto Terms of Use</a>
      {' '}and{' '}
      <a target='_blank' className='inline-text-link' rel='noopener noreferrer' href={getDomain('/privacy', true)}> Privacy Policy</a>.
    </div>
  )
}

export default SignupTerms
