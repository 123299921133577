import { useDispatch, useSelector } from 'react-redux'
import { useQuery } from 'hooks/useQuery'
import { FormProvider, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { seoTitleTemplate, Mixpanel } from 'utils'
import { images } from 'assets'
import { SeoMeta, ConditionalRender, Button, PasswordInput } from 'components'
import { schema } from 'schemas/confirmPasswordSchema'
import { resetPassword } from 'slices/userSlice'
import { useHistory } from 'react-router-dom'

const ConfirmResetPassword = () => {
  const { resetPasswordStatus, mfaRequired, submitLoading } = useSelector((state) => state.userSlice)
  const dispatch = useDispatch()
  const history = useHistory()
  const query = useQuery()
  const methods = useForm({ mode: 'onTouched', resolver: yupResolver(schema) })
  const { handleSubmit, formState: { isDirty, isValid } } = methods

  const onSubmit = (formValues) => {
    const code = query.get('code')
    dispatch(resetPassword({ code, password: formValues.password })).then(({ meta }) => {
      if (meta.requestStatus === 'fulfilled') {
        Mixpanel.track('Submit Password Reset')
      }
    })
  }

  return (
    <>
      <SeoMeta title={seoTitleTemplate('Confirm Reset Password')} />
      <div className='my-8 mx-2 md:mt-8 md:mb-[100px] md:mx-[5vw] lg:mt-8 lg:mb-[150px] lg:mx-[25vw] lg:min-h-[50vw] lg:pb-[100px]'>
        <ConditionalRender isVisible={resetPasswordStatus === 'initial'}>
          <FormProvider {...methods}>
            <div className='page-container'>
              <div className='w-full max-w-[906px] my-0 mx-auto'>
                <h1>Reset password</h1>
                <form className='grid-old' onSubmit={handleSubmit(onSubmit)}>
                  <div className='column sixteen'>
                    <PasswordInput
                      name='password'
                      label='New Password'
                      ariaLabel='new-password-input'
                      disabled={submitLoading}
                    />
                  </div>
                  <div className='column sixteen'>
                    <PasswordInput
                      name='confirmPassword'
                      label='Confirm Password'
                      ariaLabel='confirm-password-input'
                      disabled={submitLoading}
                    />
                  </div>
                  <div className='column sixteen'>
                    <div className='flex justify-start'>
                      <Button
                        disabled={!isDirty || submitLoading || !isValid}
                        loading={submitLoading}
                      >
                        Reset Password
                      </Button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </FormProvider>
        </ConditionalRender>
        <ConditionalRender isVisible={resetPasswordStatus === 'success'}>
          <div className='flex flex-col justify-center text-center'>
            <img alt='You have successfully reset your password' src={images.success} className='h-[100px]' />
            <p className='medium_1'>You have successfully reset your password.</p>
            {mfaRequired && (
              <div className='flex justify-center'>
                <Button
                  onClick={() => history.push('/signin')}
                >
                  Sign in
                </Button>
              </div>
            )}
          </div>
        </ConditionalRender>
        <ConditionalRender isVisible={resetPasswordStatus === 'failed'}>
          <div className='flex flex-col justify-center text-center'>
            <img alt='The link has expired' src={images.failed} className='h-[100px]'></img>
            <p>The link has expired.</p>
          </div>
        </ConditionalRender>
      </div>
    </>
  )
}

export default ConfirmResetPassword
