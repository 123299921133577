import Modal from 'components/Global/Modal'
import Button from 'components/Global/Button'

const ConfirmDeleteModal = ({
  setShowConfimModal,
  submitRequestDelete,
  loading
}) => {
  return (
    <>
      <Modal
        crossToClose
        modalHeader='Are you sure?'
        hideModal={() => setShowConfimModal(false)}
      >
        <div className='medium_1 content'>
          Deleting your account will remove all information you’ve submitted to
          complete your investor profile, including uploaded documents.
        </div>
        <div className='btn-group centered two'>
          <Button
            mode='tertiary'
            onClick={() => submitRequestDelete()}
            loading={loading}
          >
            Request Deletion
          </Button>
          <Button onClick={() => setShowConfimModal(false)}>
            Cancel
          </Button>
        </div>
      </Modal>
    </>
  )
}

export default ConfirmDeleteModal
