import OrderModalAccountItem from 'components/ConfrimOrder/partials/OrderModalAccountItem'
import { images } from 'assets'
import { useSelector } from 'react-redux'
import { Mixpanel } from 'utils'

const UpholdAccounts = ({
  hideModal,
  setSelectedAccount,
  insufficientError,
  setInsufficientError,
  setShowWireInstruction,
  addMargin,
  type
}) => {
  const { featureFlags } = useSelector(state => state.userSlice)

  const { upholdError, upholdCards, upholdUnderReview } = useSelector((state) => state.walletSlice)

  const handleOnSelect = (acc) => {
    setSelectedAccount({ ...acc, method: 'Uphold' })

    if (insufficientError) {
      setInsufficientError(null)
    }
    hideModal()

    if (type === 'funding') {
      setShowWireInstruction(false)
      Mixpanel.track('Select Funding Method', { 'Funding Method': 'Uphold', Currency: acc.currency })
    }
  }

  if (type === 'withdraw' && !featureFlags.UpholdWithdrawalEnabled) {
    return null
  }

  return (
    <div className='order-uphold'>
      <div className={`order-modal-account-header ${!upholdUnderReview ? null : 'gray'} ${addMargin ? 'margin' : null}`}>
        <div className='medium_2'>
          <img src={images['wallet-uphold']} alt='uphold' />
          Uphold
        </div>
        <span className='medium_1 note'>{!upholdUnderReview ? 'Fees may apply' : 'Under Review'}</span>
      </div>
      {upholdError && (
        <div className='medium_1 order-uphold-error'>
          Please check your email. {upholdError}
        </div>
      )}
      {upholdCards?.map((account) => (
        <OrderModalAccountItem
          key={account.id}
          account={account}
          onSelect={(acc) => handleOnSelect(acc)}
        />
      ))
      }
    </div>
  )
}

export default UpholdAccounts
