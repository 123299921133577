import { useFormContext } from 'react-hook-form'
import FinancialFields from './FinancialFields'
import { useSelector } from 'react-redux'
import Checkbox from 'components/Global/Inputs/Checkbox'
import Button from 'components/Global/Button'
import { useHistory } from 'react-router-dom'
import ProfileNextBtn from './ProfileNextButton'
import { allocationOptions, annualIncomeOptions, netWorthOptions } from 'components/data'
import { useEffect } from 'react'
import { Mixpanel } from 'utils'
import TrustedContact from './TrustedContact'

const FinancialForm = ({ onSubmit, countries, setValidateTrustedContact,getCountriesList, getRegionsList, trustedContactRegions, setTrustedContactRegions}) => {
  const history = useHistory()
  const { handleSubmit, watch, reset, setValue } = useFormContext()
  const { profileLoading, userProfile, initialValues } = useSelector(state => state.profile)
  const { featureFlags: { CrsCheckEnabled } } = useSelector(state => state.userSlice)
  const isChecked = watch('acknowlegeCheckbox')

  useEffect(() => {
    Mixpanel.track('Onboarding View Financial Profile Page')
  }, [])

  useEffect(() => {
    reset(initialValues)
    setValue('annualIncome', annualIncomeOptions[initialValues?.annualIncome])
    setValue('networthExcludingResidence', netWorthOptions[initialValues?.networthExcludingResidence])
    setValue('capitalAllocation', allocationOptions[initialValues?.capitalAllocation])
  }, [initialValues])

  const formSubmit = (data) => {
    const annualIncome = Object.keys(annualIncomeOptions).find(key => annualIncomeOptions[key] === data.annualIncome)
    const netWorth = Object.keys(netWorthOptions).find(key => netWorthOptions[key] === data.networthExcludingResidence)
    const allocation = Object.keys(allocationOptions).find(key => allocationOptions[key] === data.capitalAllocation)

    // Map selected sectors from multiselect for backend payload
    const sectors = data.sectors?.map(s => ({ name: s.label, sectorId: s.value }))

    const body = {
      ...data,
      annualIncome,
      networthExcludingResidence: netWorth,
      capitalAllocation: allocation,
      sectors
    }

    // If the acknowlegeCheckbox is checked,
    // send the current date in the payload
    if (isChecked) {
      if (!userProfile?.acknowledgedCrsAt && CrsCheckEnabled) {
        body.acknowledgedCrsAt = new Date()
      }
      if (!userProfile?.acknowledgedRiskAt) {
        body.acknowledgedRiskAt = new Date()
      }
    }

    onSubmit(body)
  }

  return (
    <>
      <form onSubmit={handleSubmit(formSubmit)}>
        <FinancialFields />
        <TrustedContact
          countries={countries}
          setValidateTrustedContact={setValidateTrustedContact} 
          getCountriesList={getCountriesList}
          getRegionsList={getRegionsList}
          trustedContactRegions={trustedContactRegions}
          setTrustedContactRegions={setTrustedContactRegions}
        />
        {(!userProfile?.acknowledgedRiskAt || (!userProfile?.acknowledgedCrsAt && CrsCheckEnabled)) &&
          <>
            <div className='large_1'>Acknowledgment</div>
            <Checkbox
              name='acknowlegeCheckbox'
              ariaLabel='acknowlegeCheckbox'
              disabled={profileLoading}
            >By checking this box, I hereby certify that I understand that investing in securities in private companies is speculative and involves a high degree of risk. I am prepared to withstand a total loss of my investment. I will complete my own independent due diligence before investing in securities or financial instruments, including obtaining additional information, opinions, financial projections, and legal or other investment advice. I have sufficient knowledge and experience to be investing in private equities, and I'm not subject to backup tax withholding. Furthermore, I certify that the information I provided is true and accurate to the best of my knowledge and belief.</Checkbox>
          </>
        }
        <div className='btn-group left'>
          <Button
            disabled={profileLoading}
            mode='tertiary'
            style={{ marginRight: '10px' }}
            onClick={() => history.push('/profile')}>
            Back
          </Button>
          <ProfileNextBtn
            onSubmit={onSubmit}
          />
        </div>
      </form>
    </>
  )
}
export default FinancialForm
