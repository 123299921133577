import { useState } from 'react'
import { Button } from 'components'
import Checkbox from 'components/Global/Inputs/Checkbox'
import { FormProvider, useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import { getInvestorStatus, saveAccreditedInvestorStatusChoice } from 'slices/investorStatusSlice'
import { Mixpanel } from 'utils'
import AccreditationHeaderRevamp from './AccreditationHeaderRevamp'
import EditProfileDetailsButton from './EditProfileDetailsButton'

const NoRegCountriesSection = () => {
  const methods = useForm()
  const { watch } = methods
  const dispatch = useDispatch()
  const choice = watch('choice')

  const { userFullName, accreditedCountry, accreditedStatus } = useSelector(state => state.investorStatusSlice)
  const [loading, setLoading] = useState(false)

  const onSubmit = async () => {
    setLoading(true)
    Mixpanel.track('Onboarding Click Submit Verification', { 'Accreditation Method': 'Auto', 'Accredited Country': accreditedCountry, 'Accreditation Status': accreditedStatus })
    const res = await dispatch(saveAccreditedInvestorStatusChoice({ choice }))
    const { meta } = res
    if (meta.requestStatus === 'fulfilled') {
      setLoading(false)
      await dispatch(getInvestorStatus())
    }
  }

  return (
    <FormProvider {...methods}>
      <AccreditationHeaderRevamp />
      <form className='no-reg-container'>
        <div className='large_1 acc-nav-title space-above-sm'>Please confirm that the following statements are true:</div>
        <ol className='no-reg-container_ordered-list-container'>
          <li>I <b>{userFullName.toUpperCase()}</b> am a citizen of <b>{accreditedCountry.toUpperCase()}</b> and not of the USA</li>
          <li>My investments are for my own account and not on behalf of any citizen of the USA</li>
          <li>There are no laws or regulations in my home country that restrict me from making investments in the unlisted, private companies</li>
          <li>I am financially sophisticated. I accept and acknowledge the risk of loss associated with investments in unlisted, private companies. I understand that these companies do not report and disclose their operating and financial data in the way that public companies do
            , that their securities are not freely tradeable, and that the market for these private securities are illiquid and inefficient.
          </li>
        </ol>
        <Checkbox label='I confirm I have read the above statements, and it is accurate and correct.' name='choice' value='UNREGULATED_COUNTRY' ariaLabel='choice'/>
        <div className='btn-group left info-container two'>
          <EditProfileDetailsButton />
          <Button loading={loading} disabled={!choice} onClick={onSubmit} type='button'>Submit</Button>
        </div>
      </form>
    </FormProvider>
  )
}

export default NoRegCountriesSection
