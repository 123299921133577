import { images } from 'assets'
import Button from '../../Global/Button'
const SecurityItem = ({
  option,
  name,
  description,
  icon,
  handelClick,
  email,
  disabled = false
}) => {
  return (
    <div className='box-item'>
      <div className='box-item-icon'>
        <img alt={`Icon representing ${name}`} src={images[`${icon}`]} />
      </div>
      <div className='box-item-description'>
        {name}
        <span className='medium_1 box-item-sub-title'>
          {description} {option.type === 'EMAIL' && email}
        </span>
      </div>
      {option.verified && (
        <div className='verified'>
          <img
            width={25}
            alt={`${name} is verified`}
            src={images.checkmark}
          />
        </div>
      )}
      {option.verified ? (
        <Button
          disabled={disabled}
          className='box-item-action btn sm'
          mode='secondary'
          onClick={() => handelClick(option.userMfaId)}
          ariaLabel={`Disable ${option.type.toLowerCase()}`}
        >
          Disable
        </Button>
      ) : (
        <Button
          className='box-item-action btn sm'
          mode='primary'
          onClick={() => handelClick(`${option.type.toLowerCase()}`)}
          ariaLabel={`Set Up ${option.type.toLowerCase()}`}
        >
          Set Up
        </Button>
      )}
    </div>
  )
}

export default SecurityItem
