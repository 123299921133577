import Button from 'components/Global/Button'
import { useHistory } from 'react-router-dom'
import { Mixpanel, getDevice, MOBILE_WIDTH_SIZE, Fullstory } from 'utils'
import useWindowSize from 'hooks/useWindowSize'
import { images } from 'assets'
import { ConditionalRender } from 'components'
import ProgressBarRevamp from 'components/InvestorStatus/partialsRevamp/ProgressBarRevamp'

const IdentityCTA = ({ showQrCode, openPlaid, idvFeatureValue, kycStatus, plaidFail }) => {
  const history = useHistory()
  const { width } = useWindowSize()
  const isMobile = width <= MOBILE_WIDTH_SIZE
  const isPlaid = idvFeatureValue === 'plaid'

  const openMicroBlink = () => {
    isMobile ? history.push('/identity/auto-verification/1') : showQrCode()
  }

  const handleVerifyClick = () => {
    Mixpanel.register({ Device: getDevice() })
    Mixpanel.track('Click Begin ID Verification')
    Fullstory.track('Click Begin ID Verification')
    if (idvFeatureValue === 'plaid') {
      openPlaid()
    } else if (idvFeatureValue === 'persona') {
      openMicroBlink()
    } else {
      openMicroBlink()
    }
  }

  return (
    <div className='page-container'>
      <div className='inner-container identity-verification'>
        <h1>Investor Profile</h1>
        <ProgressBarRevamp step={1} type='half' />
        <ConditionalRender isVisible={kycStatus === 'FAILED'}>
          <div className='identity-verification-failed'>
            <img src={images.identity_error} alt='Identity Verification Failed' aria-label='verification-failed'/>
            <div>
              <div className='medium_2'>Verification Failed</div>
              <div>
                <ConditionalRender isVisible={!plaidFail} className='medium_1 gray4'>
                  { isPlaid ? 'We’re having some trouble verifying your identity. Please try again.' : 'We couldn’t verify your identity. Please try again or submit your information for manual verification.'}
                </ConditionalRender>
                <ConditionalRender isVisible={plaidFail} className='medium_1 gray4'>
                  Sorry, we couldn’t verify your identity. Please  <a
                    onClick={() => history.push('/contact')}
                    className='link primary contact-us in-line'
                  >Contact Us</a> for help
                </ConditionalRender>
              </div>
            </div>
          </div>
        </ConditionalRender>
        <ConditionalRender isVisible={kycStatus === 'PENDING_AUTO_VERIFICATION' || kycStatus === 'PENDING_MANUAL_VERIFICATION'}>
          <div className='identity-verification-failed'>
            <img src={images.identity_pending} alt='Identity Verification Pending' aria-label='verification-pending'/>
            <div>
              <div className='medium_2'>Verification Pending</div>
              <div className='medium_1 gray4'>
                Please wait while we processes your identity verification.
              </div>
            </div>
          </div>
        </ConditionalRender>
        <div className='identity-verification-container'>
          <div className='large_1 header'>Help us verify your identity</div>
          <img src={isPlaid ? images.identity_plaid : images.identity_micoblink} alt='Verify Your ID' aria-label='verification-image'/>
          <p className='medium_1 lg:mb-10 mb-8'>To comply with regulations and keep your account secure, we need to make sure you’re you.</p>
          <div className='flex justify-start items-start text-left gray3 body_1 lg:mb-6 mb-8'>
            <img className='!my-0 !mr-2' src={images['lock']} alt='Secure' aria-label='lock'/>
            {isPlaid ? 'Linqto uses Plaid, trusted by over 8,000 leading brands, to securely verify your identity.' : 'You will need to take a picture of your government-issued ID and a selfie.'}
          </div>
          <div className='flex justify-start items-start text-left gray3 body_1 lg:mb-6 mb-8'>
            <img className='!my-0 !mr-2' src={images['perm_phone_msg']} alt='Secure' aria-label='perm_phone_msg'/>
            By providing a telephone number and submitting the form you are consenting to be contacted by SMS text message. Message & data rates may apply. Reply STOP to opt out of further messaging.
          </div>
          <Button
            aria-label='verification-btn'
            onClick={() => handleVerifyClick()}
            mode={kycStatus === 'FAILED' && !isPlaid ? 'secondary' : 'primary'}
            customClass='full-width'
            disabled={kycStatus === 'PENDING_AUTO_VERIFICATION' || kycStatus === 'PENDING_MANUAL_VERIFICATION' || plaidFail}>
            {kycStatus === 'FAILED' && !isPlaid ? 'Try Again' : 'Verify with Plaid'}
          </Button>
          {!isPlaid && kycStatus === 'FAILED' &&
            <Button aria-label='manual-verification-btn' onClick={() => history.push('/identity/manual-verification')} customClass='full-width'>Manual Verification</Button>}
        </div>
      </div>
    </div>
  )
}

export default IdentityCTA
