import { Modal } from 'components'
import Button from './Button'
import ReactHtmlParser from 'react-html-parser'

const ErrorModal = ({ twoButtons, error, hideModal, message = '', crossToClose = false, centered = false }) => {
  if (!error.type) return null
  return (
    <Modal hideModal={hideModal} modalHeader={error.title} crossToClose={crossToClose}>
      <div className={`medium_1 content ${centered ? 'text-center' : ''}`}>{ReactHtmlParser(error.message)}</div>
      {twoButtons
        ? <div className='btn-group two centered'>
          <Button mode='tertiary' onClick={() => hideModal(false)}>No</Button>
          <Button onClick={() => hideModal(true)}>Yes</Button>
        </div>
        : <div className='btn-group centered'><Button onClick={hideModal}>{message !== '' ? message : 'OK'}</Button></div>}
    </Modal>
  )
}

export default ErrorModal
