import Modal from 'components/Global/Modal'
import Button from 'components/Global/Button'
import { useHistory } from 'react-router-dom'

const ConfirmAutomaticDeleteModal = ({
  setShowConfimModal,
  submitRequestDelete,
  loading
}) => {
  const history = useHistory()
  return (
    <>
      <Modal
        crossToClose
        modalHeader='Are you sure?'
        hideModal={() => setShowConfimModal(false)}
      >
        <div className='medium_1 content'>
          Deleting your account will remove all information you’ve submitted.
        </div>
        <div className='btn-group centered two'>
          <Button mode='tertiary' onClick={() => setShowConfimModal(false)}>
            Cancel
          </Button>
          <Button
            onClick={() => submitRequestDelete()}
            loading={loading}
          >
            Delete Account
          </Button>
        </div>
        <div className='medium_1 space-above-sm'>
        Need help?{' '}
          <Button
            onClick={() => history.push('/contact')}
            className='link primary contact-us'
          >
            Contact us
          </Button>
        </div>
      </Modal>
    </>
  )
}

export default ConfirmAutomaticDeleteModal
