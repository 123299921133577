import { useState } from 'react'
import Tooltip from 'components/Global/Tooltip'
import ProgressBarRevamp from 'components/InvestorStatus/partialsRevamp/ProgressBarRevamp'
import ProgressBar from './ProgressBar'
import { useLDFlags } from 'utils'
import NewProgressBar from './NewProgressBar'

const content = 'Your legal name is used for identity verification, tax reporting, and other legal documents. Contact support for questions.'

const ProfileHeader = ({
  email,
  legalName,
  isAccredited,
  hasKyc,
  isRetailUser,
  activeStep
}) => {
  const [show, setShow] = useState(false)
  const { accreditationRevamp, suitabilityQuestions } = useLDFlags(['accreditationRevamp', 'suitabilityQuestions'])
  return (
    <div className='header-progressbar-wrapper'>
      <h1 className='subinfo'>
        Investor Profile
        {isAccredited && <div className='medium_1 web-account-email'>
          <span>
            Account Email: <span className='profile-email'>{email}</span>
          </span>
          <br />
          <span>
            Legal Name: <span className='profile-email'>{legalName}</span>
          </span>
          <Tooltip
            content={content}
            showHover={show}
            setShowHover={setShow}
            configurable={false}
          />
        </div>}
      </h1>
      {(hasKyc && !isAccredited && !isRetailUser) && <>
      
        {suitabilityQuestions ? <NewProgressBar activeStep={activeStep} /> : accreditationRevamp ? 
          <ProgressBarRevamp step={2} type={activeStep === 'basicInfo' ? 'half' : 'full'} />
          : (<><hr className='title-separator' />
            <ProgressBar activeStep={activeStep}/></>
          )}
      </>}
      {(isAccredited || isRetailUser)&&
      <div className='mobile-account-email'>
        <span className='gray4'>
          Account Email: <span className='profile-email gray4'>{email}</span>
        </span>
        <br />
        <span className='gray4'>
          Legal Name: <span className='profile-email gray4'>{legalName}</span>
        </span>
        <Tooltip
          content={content}
          showHover={show}
          setShowHover={setShow}
        />
      </div>
      }
    </div>
  )
}

export default ProfileHeader
