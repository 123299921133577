import ErrorMessage from 'components/Global/Inputs/ErrorMessage'
import TextInput from 'components/Global/Inputs/TextInput'
import TooltipLabel from 'components/Global/TooltipLabel'
import React, { useRef } from 'react'
import { useFormContext } from 'react-hook-form'
import ReactInputMask from 'react-input-mask'

const SSNMaskedInput = ({ name, content, label, ariaLabel, disabled = false, defaultValue }) => {
  const {
    register,
    formState: { errors, dirtyFields },
    setValue,
    watch
  } = useFormContext()
  const inputRef = useRef()

  const taxId = watch('taxId')
  if (defaultValue?.includes('***')) {
    return (
      <TextInput
        name={name}
        label='Social Security # or ITIN #'
        placeholder='000-00-0000'
        maxLength='11'
        content={content}
        ariaLabel={ariaLabel}
        disabled={disabled}
        id={name}
      />
    )
  }

  if (!taxId || taxId === '___-__-____') {
    return (
      <div className={`input-group ${errors[name] && 'error'}`}>
        <TooltipLabel content={content} label={label} htmlFor={name} />
        <ReactInputMask
          mask='999-99-9999'
          alwaysShowMask
          {...register(name)}
          aria-label={ariaLabel}
          disabled={disabled}
        />
        {errors[name] && <ErrorMessage message={errors[name].message} />}
      </div>
    )
  }

  if ((!dirtyFields || !dirtyFields[name])) {
    return (
      <TextInput
        name={name}
        label='Social Security # or ITIN #'
        placeholder='000-00-0000'
        maxLength='11'
        content={content}
        ariaLabel={ariaLabel}
        disabled={disabled}
      />
    )
  }

  const setInputRef = (ref) => {
    if (!inputRef.current) {
      inputRef.current = ref
      inputRef.current?.focus()
      setValue('taxId', '')
    }
  }

  return (
    <div className={`input-group ${errors[name] && 'error'}`}>
      <TooltipLabel content={content} label={label} htmlFor={name} />
      <ReactInputMask
        mask='999-99-9999'
        alwaysShowMask
        {...register(name)}
        inputRef={(ref) => setInputRef(ref)}
        aria-label={ariaLabel}
      />
      {errors[name] && <ErrorMessage message={errors[name].message} />}
    </div>
  )
}

export default SSNMaskedInput
