import React, { useEffect } from 'react'
import { images } from 'assets'
import Button from 'components/Global/Button'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { MOBILE_WIDTH_SIZE, Mixpanel } from 'utils'
import useWindowSize from 'hooks/useWindowSize'

const FinanciallySophisticatedResultRevamp = () => {
  const history = useHistory()
  const {
    hasPassedAccreditationQuiz,
    scorePercentage
  } = useSelector((state) => state.investorStatusSlice)
  const { width } = useWindowSize()

  useEffect(() => {
    Mixpanel.track(`Financially Sophisticated Test - ${hasPassedAccreditationQuiz ? 'Success' : 'Fail'}`, { Score: `${scorePercentage}%` })
  }, [hasPassedAccreditationQuiz, scorePercentage])

  const redirectSuccessUser = (webRoute, mobileRoute) => {
    if (window.ReactNativeWebView) {
      window.ReactNativeWebView.postMessage(mobileRoute)
    } else {
      history.push(webRoute)
    }
  }

  const handleDifferentWay = async () => {
    history.push('/investor-status')
    window.location.reload()
  }

  let resultsJSX = ''

  if (hasPassedAccreditationQuiz) {
    resultsJSX = (
      <>
        <div className='financially-sophisticated-result-container__header-container'>
          <img src={images.acc_financially_sophisticated_success} alt='Congratulations!'/>
          <span className='giant_2'>Congratulations!</span>
        </div>
        <span className='medium_1 text-center financially-sophisticated-result-container__copy-container'>
          With your Financially Sophisticated test complete, you’re now ready to invest in select companies as a Non-Accredited Investor
        </span>
        <div className={`btn-group ${width <= MOBILE_WIDTH_SIZE && 'full-width reverse'}`}>
          <Button onClick={() => redirectSuccessUser('/products', 'navigateToInvest')} mode='tertiary'>Explore Investments</Button>
          <Button onClick={() => redirectSuccessUser('/cash-account/add-funds', 'navigateToCashAccount')}>Add Funds</Button>
        </div>
      </>
    )
  } else {
    resultsJSX = (<>
      <div className='financially-sophisticated-result-container__header-container'>
        <img src={images.acc_financially_sophisticated_failed} alt='We’re sorry. You did not pass'/>
        <span className='giant_2'>We’re sorry. You did not pass.</span>
      </div>
      <span className='medium_1 text-center financially-sophisticated-result-container__copy-container'>
        You have the option to verify your accreditation status a different way.
      </span>
      <div className={`btn-group ${width <= MOBILE_WIDTH_SIZE && 'full-width'}`}>
        <Button onClick={handleDifferentWay}>Try A Different Way</Button>
      </div>
    </>
    )
  }

  return (
    <div className='page-container investor-status-container'>
      <div className='inner-container'>
        <div className='financially-sophisticated-result-container'>
          {resultsJSX}
        </div>
      </div>
    </div>
  )
}

export default FinanciallySophisticatedResultRevamp
