import MfaModalCTA from './MfaModalCTA'
import MfaInnerContent from './MfaInnerContent'
import MfaModalFormError from './MfaModalFormError'
import { useFormContext } from 'react-hook-form'
import { useState } from 'react'
import { useSelector } from 'react-redux'

const MfaModalForm = ({
  type,
  steps,
  formErr,
  setFormErr,
  setSteps,
  onSubmit
}) => {
  const { handleSubmit } = useFormContext()
  const { currentMfa } = useSelector((state) => state.securitySlice)

  const [copiedKey, setCopiedKey] = useState(false)
  const copySecretKey = (key) => {
    navigator.clipboard.writeText(key)
    setCopiedKey(true)
    setTimeout(() => setCopiedKey(false), 2000)
  }

  const openAuthenticatorApp = () => {
    const otpAuthUrl = currentMfa.otpAuthUrl
    window.open(otpAuthUrl)
    return false
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <MfaInnerContent
        type={type}
        steps={steps}
        setFormErr={setFormErr}
        openAuthenticatorApp={openAuthenticatorApp}
        copiedKey={copiedKey}
        copySecretKey={copySecretKey}
      />
      <MfaModalFormError error={formErr} />
      <MfaModalCTA
        steps={steps}
        setSteps={setSteps}
        onSubmit={onSubmit}
        type={type}
      />
    </form>
  )
}

export default MfaModalForm
