import { Modal } from 'components'
import Button from '../../Global/Button'

const CRDSubmitSuccessModal = ({ hideModal }) => {
  return (
    <Modal
      crossToClose
      hideModal={hideModal}
      modalHeader='CRD # is Under Review by Our Team'>
      <div className='medium_1 content'>Your CRD# was successfully submitted.</div>
      <div className='medium_1 content'>Our team will review your submission right away, and
        confirm that your license is active. You’ll receive an email when you are verified.
      </div>
      <div className='btn-group centered'>
        <Button onClick={hideModal}>OK</Button>
      </div>
    </Modal>
  )
}

export default CRDSubmitSuccessModal
