import Button from 'components/Global/Button'
import { useFormContext } from 'react-hook-form'
import { useSelector } from 'react-redux'

const SummaryFormCTA = ({ onSubmit, cancelAddFunds, type, hasError }) => {
  const { otpRequired, loading } = useSelector(
    (state) => state.walletSlice
  )

  const {
    handleSubmit,
    formState: { errors },
    watch
  } = useFormContext()
  const upholdOTPValue = watch('upholdOTP')

  return (
    <div className='btn-group two'>
      <Button
        mode='tertiary'
        type='button'
        onClick={cancelAddFunds}
      >
        Back
      </Button>
      <Button
        onClick={() => handleSubmit(onSubmit)}
        type='submit'
        disabled={
          loading || hasError ||
          [Object.keys(errors).length] > 0 ||
          (type === 'Uphold' && otpRequired && (!upholdOTPValue || upholdOTPValue?.length < 6))
        }
        loading={loading}
      >
        Confirm
      </Button>
    </div>
  )
}

export default SummaryFormCTA
