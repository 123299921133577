import { images } from 'assets'
import { useHistory } from 'react-router-dom'

const ClientStatus = ({ status, userId, hasPendingOrder }) => {
  const history = useHistory()

  if (status === 'NEEDS_KYC' || status === 'NEEDS_BASIC_INFO') {
    return (
      <div className='error-info'>
        <img
          width={16}
          alt='Profile Error'
          src={images.ria_error}
          className='error-icon'
        />
        {status === 'NEEDS_KYC'
          ? 'Identity Verification Required'
          : 'Profile Incomplete'}
      </div>
    )
  }
  if (status === 'NEEDS_ACCREDITATION') {
    return (
      <a
        className='link primary'
        onClick={() =>
          history.push(`/ria/verify-client-accreditation/${userId}`)
        }
      >
        <img
          width={16}
          alt='Verify Client Accreditation'
          src={images.ria_error}
          className='error-icon'
        />
        Verify Client Accreditation
      </a>
    )
  }
  if (hasPendingOrder) {
    return (
      <div className='flag-container'>
        {hasPendingOrder && <div className='body_1 flag pending'>ORDER PENDING</div>}
      </div>
    )
  }
  return <div />
}

export default ClientStatus
