import { Modal } from 'components'
import { getDomain } from 'utils'

const OriginationOfferNotAvailableModal = () => {
  return (
    <Modal modalHeader='No Longer Purchasing'>
      <div className='medium_1 content'>
        Thank you for your interest in selling your shares to Linqto. For this company, we have just met our quota and are no longer purchasing.
      </div>
      <div className='medium_1 content'>
        Please check back soon.
      </div>
      <div className='btn-group centered'>
        <a className='btn primary lg' href={getDomain('/', true)}>Return to Home</a>
      </div>
    </Modal>
  )
}

export default OriginationOfferNotAvailableModal
