import React, { useState } from 'react'
import { useFormContext } from 'react-hook-form'
import TooltipLabel from '../TooltipLabel'
import ErrorMessage from './ErrorMessage'
import PhoneInput from 'react-phone-number-input/react-hook-form'
import metadata from 'libphonenumber-js/metadata.min.json'

const PhoneNoInput = ({
  name,
  label,
  content = '',
  disabled = false,
  control,
  ariaLabel = '',
  autoFocus = false,
  countries = []
}) => {
  const [defaultCountry, setDefaultCountry] = useState('US')

  const {
    formState: { errors }
  } = useFormContext()
  return (
    <div className={`input-group ${errors[name] && 'error'}`}>
      <TooltipLabel content={content} label={label} htmlFor={name} />
      <PhoneInput
        id={name}
        metadata={metadata}
        disabled={disabled}
        name={name}
        control={control}
        international
        countryCallingCodeEditable={false}
        defaultCountry= {defaultCountry}
        aria-label={ariaLabel}
        autoFocus={autoFocus}
        withCountryCallingCode={false}
        displayInitialValueAsLocalNumber={true}
        onCountryChange={(val) => {
          setDefaultCountry(val)
        }}
        countries={countries}
      />
      {errors[name] && <ErrorMessage message={errors[name].message} />}
    </div>
  )
}

export default PhoneNoInput
