import { useFormContext } from 'react-hook-form'
import { useSelector } from 'react-redux'
import Button from 'components/Global/Button'

const FBOWithdrawCTA = ({
  onSubmit
}) => {
  const { errors, handleSubmit } = useFormContext()
  const { loading } = useSelector((state) => state.walletSlice)

  return (
    <>
      <div className='medium_1 dark space-above-lg space-below-sm mobile-centered'>Withdrawal requests typically take about 3 business days to process.</div>
      <div className='btn-group full-width'>
        <Button
          onClick={() => handleSubmit(onSubmit)}
          disabled={errors?.length > 0 || loading}
          loading={loading}
          customClass='full-width'
          type='submit'
        >
          Request Withdrawal
        </Button>
      </div>
    </>
  )
}

export default FBOWithdrawCTA
